import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <h1 className="text-4xl">Oops!</h1>
      <p className="mt-4">Sorry, an unexpected error has occurred.</p>
      <p className="mt-4 text-gray-500">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}