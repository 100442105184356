import { useEffect, useState } from 'react';
import { useGetModelsQuery } from '../api/apiSlice';
import uuid from 'react-uuid';
import ReactPaginate from 'react-paginate';
import Loader from './common/Loader';
import ModelCard from './products/ModelCard';

const modelsPerPage = 16;

function Models() {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [modelsToShow, setModelsToShow] = useState([]);
  
  const {
    data: models,
    isLoading,
    isSuccess,
  } = useGetModelsQuery();

  const handlePageClick = (event) => {
    const newOffset = (event.selected * modelsPerPage) % models.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  const isLastPage = () => {
    return currentPage + 1 === numberOfPages;
  };

  useEffect(() => {
    if (models && models.length) {
      const pageCount = Math.ceil(models.length / modelsPerPage);
      setNumberOfPages(pageCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (models && models.length) {
      const endOffset = itemOffset + modelsPerPage;
      const currentItems = models.slice(itemOffset, endOffset);
      setModelsToShow([...currentItems]);
    }
  }, [itemOffset, models]);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center">
          {/* <Loader /> */}
        </div>
      ) : (
        <div className="flex flex-col">
          <h1 className="font-bold text-gray-700 text-3xl">
            Svi proizvodi
          </h1>
          <div className="mt-10 grid-container">
            {isSuccess && models.length && modelsToShow.map((model) => {
              return <ModelCard key={uuid()} model={model} />
            })}
          </div>
          <ReactPaginate
            containerClassName="flex flex-wrap self-center xl:self-end mt-10 text-sm text-gray-700 select-none"
            pageClassName="mx-2"
            activeClassName="text-orange-600 font-medium"
            breakLabel="..."
            nextLabel={isLastPage() ? '' : '»'}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={numberOfPages}
            previousLabel={itemOffset ? '«' : ''}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
}
    
export default Models;