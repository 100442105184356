import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { setRandomModels } from './products/randomModelsSlice';
import { useGetModelsQuery } from '../api/apiSlice';
// import Loader from './common/Loader';
import ModelCard from './products/ModelCard';

const numberOfModelsToShow = 12;

function Main() {
  const dispatch = useDispatch();
  const {
    data: models,
    isLoading
  } = useGetModelsQuery();
  const randomModels = useSelector((state) => state.randomModels.randomModels);

  const getRandomModels = () => {
    const modelsToShow = [];
    for (let modelNumber = 0; modelNumber < numberOfModelsToShow; modelNumber++) {
      modelsToShow.push(models[Math.floor(Math.random() * models.length)]);
    }
    return modelsToShow;
  };

  const navigateToCatalogue = (catalogue) => {
    if (catalogue === 'rokovnici') window.open('https://anyflip.com/ypax/fzmh', '_blank');
    if (catalogue === 'ostalo') window.open('https://anyflip.com/ypax/bgzm', '_blank');
  }

  useEffect(() => {
    if (models && models.length && !randomModels.length) {    
      dispatch(setRandomModels([...getRandomModels()]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center">
          {/* <Loader /> */}
        </div>
      ) : (
        <>
          <div className="flex items-center gap-4">
            <div
              className="w-56 h-72 rounded-md border cursor-pointer katalog-rokovnici"
              onClick={() => navigateToCatalogue('rokovnici')}
            />
            <div
              className="w-56 h-72 rounded-md border cursor-pointer katalog-ostalo"
              onClick={() => navigateToCatalogue('ostalo')}
            />
            <div className="hidden lg:block flex-1 h-72 rounded-md border border-solid border-gray-200 cover" />
          </div>
          <h1 className="font-bold text-gray-700 mt-10 text-3xl">
            Neki od naših modela
          </h1>
          <div className="mt-10 grid-container">
            {randomModels.map((model) => {
              return <ModelCard key={uuid()} model={model} />
            })}
          </div>
        </>
      )}
    </>
  );
}

export default Main;
