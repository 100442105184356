import uuid from 'react-uuid';
import { Link } from 'react-router-dom';
import { useGetModelQuery } from '../../api/apiSlice';

function ModelCard({ model }) {
  const {
    data: additionalModelInfo,
    isLoading
  } = useGetModelQuery(model.Id);

  const getStockNumber = () => {
    let stockNumber = 0;
    additionalModelInfo.Colors.forEach((color) => {
      color.Sizes.forEach((size) => {
        size.Product.Stocks.forEach((stock) => {
          stockNumber += stock.Qty
        })
      })
    })
    return stockNumber;
  };

  return (
    <>
      {isLoading && (
        <div
          className="flex flex-col h-96 border border-solid border-zinc-100
            shadow-md rounded-md cursor-pointer overflow-hidden model-card"
        />
      )}
      {additionalModelInfo && (
        <Link
          to={`/modeli/${model.Id}`}
          className="flex flex-col h-96 border border-solid border-zinc-100
            shadow-md rounded-md cursor-pointer overflow-hidden model-card pb-2"
        >
          <div className="flex items-center justify-center">
            <div className="px-8 py-4 h-56 w-64">
              <img src={model.ImageWebP} alt="product" />
            </div>
          </div>
          <div className="grow text-center font-medium text-gray-700 bg-zinc-50 px-4 py-2">
            <div className="w-full flex justify-between font-bold">
              <div>
                <div className="font-normal text-left">{model.Id}</div>
                <div className="truncate w-48 text-left">{model.Name}</div>
              </div>
              <div>
                {additionalModelInfo.Colors[0].Sizes[0].Product.Price.toFixed(2)} €
              </div>
            </div>
            <div className="text-left text-xs font-normal">{model.Description}</div>
            <div className="text-left text-xs font-normal">Zalihe: {getStockNumber()}</div>
          </div>
          <div className="flex flex-wrap gap-1 px-4 mt-2">
            {additionalModelInfo.Colors && additionalModelInfo.Colors.map((color) => {
              if (color.HtmlColor) {
                return (
                  <div
                    key={uuid()}
                    className='h-5 w-5 rounded-full border-2 border-solid border-gray-200'
                    style={{ backgroundColor: color.HtmlColor }} 
                  />
                )
              }
              return (
                <div
                  key={uuid()}
                  className='h-5 w-5 rounded-full cursor-pointer overflow-hidden
                    border-2 border-solid border-gray-200'
                >
                  <img src={color.Image} alt="color" />
                </div>
              ) 
            })}
          </div>
        </Link>
      )}
    </>
  );
}
    
export default ModelCard;