import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { useGetModelQuery } from '../api/apiSlice';
// import Loader from './common/Loader';
import GetProductInfo from './products/GetProductInfo';

function Model() {
  const { id } = useParams();
  const {
    data: model,
    isLoading
  } = useGetModelQuery(id);

  const [imageToShow, setImageToShow] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);

  const getStockNumber = () => {
    const initialValue = 0;
    const stocks = [];

    selectedColor.Sizes.forEach((size) => {
      const newStock = size.Product.Stocks.reduce((accumulator, stock) => {
        return accumulator + stock.Qty;
      }, initialValue);
      stocks.push(newStock);
    });

    return stocks.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, initialValue);
  };
  const getPriceWithTwoDecimals = (price) => {
    return price.toFixed(2);
  };
  const getModelSizes = (sizes) => {
    return [...new Set(sizes.map(size => size.Id))];
  };

  useEffect(() => {
    if (model) {
      setSelectedProduct(model.Colors[0].Sizes[0].Product);
      setSelectedColor(model.Colors[0]);
    }
  }, [model]);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center">
          {/* <Loader /> */}
        </div>
      ) : (
        <div className="flex flex-col-reverse xl:flex-row xl:justify-between xl:gap-32">
          <div className="mt-10 xl:mt-0 xl:w-1/2">
            <div className="shadow-md rounded-md">
              <div className="px-16 py-8">
                <img src={imageToShow} alt="product" />
              </div>
            </div>
            <div className="flex overflow-x-auto flex-nowrap gap-4 mt-5 pb-4 phone-scroll">
              {model.Colors.map((color) => {
                return (
                  <div
                    key={uuid()}
                    onClick={() => setSelectedColor(color)}
                  >
                    <GetProductInfo
                      productId={color.Sizes[0].Product.Id}
                      setProductImage={setImageToShow}
                      setSelectedProductInfo={setSelectedProduct}
                      isSelected={selectedProduct && selectedProduct.Id === color.Sizes[0].Product.Id}
                      returnImage
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="xl:w-1/2 text-gray-700">
            <h1 className="text-3xl xl:text-5xl font-medium">{model.Name}</h1>
            <p className="mt-3">{model.Description}</p>
            <p className="mt-3">Šifra: {id}</p>
            {model.Colors[0].Sizes[0].Id && (
              <div className="flex gap-1.5 mt-3 flex-wrap">
                Veličine: 
                {getModelSizes(model.Colors[0].Sizes).map((size) => {
                  return <p className="font-medium" key={uuid()}>{size}</p>
                })}
              </div>
            )}
            <div className="mt-5 w-full h-0.5 bg-zinc-100" />

            <p className="mt-5 text-xl font-medium">Cena</p>
            <p className="mt-3 text-3xl font-bold">{selectedProduct && getPriceWithTwoDecimals(selectedProduct.Price)}€</p>
            <div className="mt-5 w-full h-0.5 bg-zinc-100" />

            <p className="mt-5 text-xl font-medium">Stanje</p>
            <p className="mt-3 text-3xl font-bold">
              {selectedProduct && getStockNumber()} kom
            </p>
            <div className="mt-5 w-full h-0.5 bg-zinc-100" />

            <p className="mt-5 text-xl font-medium">Specifikacija</p>
            <p className="mt-3 text-sm">
              Pakovanje:&nbsp;
              <span className="font-medium">{selectedProduct && selectedProduct.Package}</span>
            </p>
            {selectedProduct && selectedProduct.Specifications && selectedProduct.Specifications.map((specification) => {
              return (
                <p key={uuid()} className="mt-3 text-sm">
                  {specification.Name}:&nbsp;
                  <span className="font-medium">{specification.Value}</span>
                </p>
              )
            })}
            <div className="mt-5 w-full h-0.5 bg-zinc-100" />

            <p className="mt-5 font-medium">Dostupne boje</p>
            <div className="mt-3 flex flex-wrap gap-3">
              {model.Colors.map((color) => {
                return (
                  <div
                    key={uuid()}
                    onClick={() => setSelectedColor(color)}
                  >
                    <GetProductInfo
                      productId={color.Sizes[0].Product.Id}
                      setProductImage={setImageToShow}
                      setSelectedProductInfo={setSelectedProduct}
                      isSelected={selectedProduct && selectedProduct.Id === color.Sizes[0].Product.Id}
                      returnColor
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
    
export default Model;