import { createSlice } from '@reduxjs/toolkit';

export const randomModelsSlice = createSlice({
  name: 'randomModelsSlice',
  initialState: {
    randomModels: []
  },
  reducers: {
    setRandomModels: (state, action) => {
      state.randomModels = action.payload;
    }
  }
});

export const { setRandomModels } = randomModelsSlice.actions;
export default randomModelsSlice.reducer;