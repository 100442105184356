import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import './index.css';
import Root from './Root';
import ErrorPage from './ErrorPage';
import Main from './components/Main';
import Models from './components/Models';
import Model from './components/Model';
import Contact from './components/Contact';
import SearchPage from './components/SearchPage';
import Category from './components/Category';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Main /> },
      { path: '/modeli', element: <Models /> },
      { path: '/modeli/:id', element: <Model /> },
      { path: '/kontakt', element: <Contact /> },
      { path: '/pretraga', element: <SearchPage /> },
      { path: '/kategorija/:id', element: <Category /> }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
