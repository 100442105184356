import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import TopBar from './components/common/TopBar';
import OutletWrapper from './components/common/OutletWrapper';
import Loader from './components/common/Loader';
import Footer from './components/common/Footer';

function Root() { 
  const [shouldProceed, setShouldProceed] = useState(false);
  const [proceedError, setProceedError] = useState(false);

  // http://localhost:4000/proceed
  useEffect(() => {
    axios.get('https://biancodesign.rs/proceed')
      .then((token) => {
        setShouldProceed(true);
      })
      .catch((err) => {
        setProceedError(true);
      })
  }, []);

  return (
    <div className="w-full">
      {shouldProceed ? (
        <>
          <TopBar />
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
          <Footer />
        </>
      ) : (
        <>
          {proceedError ? (
            <div className="flex w-full h-full justify-center items-center">
              <div className="text-3xl font-medium text-orange-500">
                Something went wrong :(
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </>
      )}
    </div>
  );
}

export default Root;
