function Footer() {
  const navigateToCatalogue = (catalogue) => {
    if (catalogue === 'rokovnici') window.open('https://anyflip.com/ypax/fzmh', '_blank');
    if (catalogue === 'ostalo') window.open('https://anyflip.com/ypax/ttwn', '_blank');
  }

  return (
    <div
      className="flex flex-col py-4 xl:py-0 gap-4 xl:flex-row xl:items-center justify-between px-4 xl:px-32 w-full xl:h-16
        border-t border-solid border-orange-600 text-gray-700 font-bold text-sm"
    >
      <div>biancodesign@mts.rs</div>
      <div
        className="cursor-pointer"
        onClick={() => navigateToCatalogue('rokovnici')}
      >
        Katalog rokovnika i kalendara
      </div>
      <div
        className="cursor-pointer"
        onClick={() => navigateToCatalogue('ostalo')}
      >
        Katalog poklona
      </div>
      <div>Bianco Design © {new Date().getFullYear()}</div>
    </div>
  );
}
  
export default Footer;