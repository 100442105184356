import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { useGetGroupsQuery, useGetModelsQuery } from '../api/apiSlice';
import ReactPaginate from 'react-paginate';
// import Loader from './common/Loader';
import ModelCard from './products/ModelCard';

const modelsPerPage = 16;

function Category() {
  const { id } = useParams();
  const { data: groups } = useGetGroupsQuery();

  const [categoryTitle, setCategoryTitle] = useState('');
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [filteredModels, setFilteredModels] = useState([]);
  const [modelsToShow, setModelsToShow] = useState([]);

  const {
    data: models,
    isLoading,
    isSuccess
  } = useGetModelsQuery();

  const handlePageClick = (event) => {
    const newOffset = (event.selected * modelsPerPage) % filteredModels.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };
  const isLastPage = () => {
    return currentPage + 1 === numberOfPages;
  };

  useEffect(() => {
    if (filteredModels.length) {
      const pageCount = Math.ceil(filteredModels.length / modelsPerPage);
      const endOffset = itemOffset + modelsPerPage;
      const currentItems = filteredModels.slice(itemOffset, endOffset);
      setNumberOfPages(pageCount);
      setModelsToShow([...currentItems]);
    }
  }, [itemOffset, filteredModels]);

  useEffect(() => {
    if (models) {
      const modelsInCategory = models.filter((model) => {
        return id === model.GroupWeb1 || id === model.GroupWeb2 || id === model.GroupWeb3;
      });
      setFilteredModels([...modelsInCategory]);
    }
  }, [models, id]);

  useEffect(() => {
    if (groups && groups.length) {
      const currentGroup = groups.find((group) => {
        return group.Code === id;
      })
      setCategoryTitle(currentGroup.Name);
    }
  }, [groups, id]);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center">
          {/* <Loader /> */}
        </div>
      ) : (
        <div className="flex flex-col">
          <h1 className="font-bold text-gray-700 text-3xl">
            {categoryTitle}
          </h1>
          <div className="mt-10 grid-container">
            {isSuccess && models.length && modelsToShow.map((model) => {
              return <ModelCard key={uuid()} model={model} />
            })}
          </div>
          <ReactPaginate
            containerClassName="flex flex-wrap self-center xl:self-end mt-10 text-sm text-gray-700 select-none"
            pageClassName="mx-2"
            activeClassName="text-orange-600 font-medium"
            breakLabel="..."
            nextLabel={isLastPage() ? '' : '»'}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={numberOfPages}
            previousLabel={itemOffset ? '«' : ''}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
}
  
export default Category;