import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';
import uuid from 'react-uuid';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useGetCategoriesQuery, useGetGroupsQuery, useGetModelsQuery } from '../../api/apiSlice';
import { ReactComponent as PhoneIcon } from '../../img/phone.svg';
import { ReactComponent as SearchIcon } from '../../img/search.svg';
import { ReactComponent as MobileSearchIcon } from '../../img/search-mobile.svg';
import { ReactComponent as MenuIcon } from '../../img/menu.svg';
import { ReactComponent as CloseIcon } from '../../img/close.svg';
import { ReactComponent as ChevronRight } from '../../img/chevron-right.svg';
import logo from '../../img/logo.png';

function TopBar() {
  const { data: groups } = useGetGroupsQuery();
  const { data: categories, isSuccess } = useGetCategoriesQuery();
  const { data: models } = useGetModelsQuery();

  const [searchQuery, setSearchQuery] = useState('');
  const [mobileSearchQuery, setMobileSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [sortedGroups, setSortedGroups] = useState(null);
  const [firstHoveredGroup, setFirstHoveredGroup] = useState(null);
  const [secondHoveredGroup, setSecondHoveredGroup] = useState(null);
  const [shouldShowCategories, setShouldShowCategories] = useState(false);
  const [shouldShowPhoneMenu, setShouldShowPhoneMenu] = useState(false);
  const [shouldShowPhoneSearch, setShouldShowPhoneSearch] = useState(false);
  const [shouldShowPhoneCategories, setShouldShowPhoneCategories] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setSearchQuery('') });
  const navigate = useNavigate();

  const handleClosePhoneMenu = () => {
    setShouldShowPhoneMenu(false);
    setShouldShowPhoneCategories(false);
  };
  const searchForModels = (searchValue) => {
    if (models) {
      const options = {
        includeScore: true,
        keys: ['Name', 'Description', 'Description2']
      };
      const fuse = new Fuse(models, options);
      const result = fuse.search(searchValue);
      setSearchResults(result);
      setSearchQuery(searchValue);
      setMobileSearchQuery(searchValue);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      navigate('/pretraga', { state: searchResults });
      setSearchQuery('');
      window.location.reload();
    }
  };
  const navigateToPage = (page) => {
    setShouldShowPhoneMenu(false);
    navigate(page);
  };
  const navigateToModel = (event, result) => {
    setShouldShowPhoneSearch(false);
    navigate(`/modeli/${result.item.Id}`);
    setSearchQuery('');
    setMobileSearchQuery('');
  };
  const navigateToCategory = (category) => {
    navigate(`/kategorija/${category.Id}`);
    window.location.reload();
  };
  const isMainCategory = (category) => {
    if (category.Parent === '*') {
      return (
        <div 
          className="xl:cursor-pointer xl:w-1/2 xl:hover:text-orange-400"
          key={category.Id}
          onClick={() => navigateToCategory(category)}
        >
          {category.Name}
        </div>
      )
    }
  };

  const resetCategories = () => {
    setShouldShowCategories(false);
    setFirstHoveredGroup(null);
    setSecondHoveredGroup(null);
  };
  const setMainGroup = (mainGroup) => {
    setFirstHoveredGroup(mainGroup);
    setSecondHoveredGroup(null);
  };
  const goToGroup = (group) => {
    navigate(`/kategorija/${group.Code}`);
    window.location.reload();
  };

  const getGroupChildren = (parentGroup) => {
    return groups.filter((group) => {
      if (group.Parent === parentGroup.Code) {
        return group;
      }
    }).map((filteredGroup) => {
      return {
        ...filteredGroup,
        children: getGroupChildren(filteredGroup)
      }
    })
  }

  useEffect(() => {
    if (groups && groups.length) {
      const groupsTree = groups.filter((group) => {
        if (!group.Parent) return group;
      }).map((parentGroup) => {
        return {
          ...parentGroup,
          children: getGroupChildren(parentGroup)
        };
      });

      setSortedGroups(_.sortBy(groupsTree, ['Sort']));
    }
  }, [groups]);

  return (
    <div className="sticky top-0 left-0 shadow-sm bg-white">
      <div
        className="w-full flex items-center gap-2 bg-orange-600 text-white font-medium
          text-left px-4 sm:px-8 xl:px-20 py-1.5 text-sm overflow-x-scroll"
      >
        <div className="w-6">
          <PhoneIcon />
        </div>
        <a href="tel:+381112440388">+381112440388</a>
        <a href="tel:+38163351445">+38163351445</a>
        <a href="tel:+381631013808">+381631013808</a>
      </div>
      <div className="w-full h-20 flex justify-between items-center px-6 xl:px-20 py-2 font-medium relative">
        <div className="h-full flex justify-between xl:justify-start w-full xl:w-auto items-center">
          <div
            className="cursor-pointer"
            onClick={() => navigate('/')}
          >
            <img className="w-32" src={logo} alt="logo" />
          </div>
          <div className="hidden xl:flex ml-10 items-center gap-8 text-gray-700 h-full">
            <div
              className="flex items-center cursor-pointer font-bold h-full pl-5"
              onMouseOver={() => setShouldShowCategories(true)}
              onMouseLeave={resetCategories}
            >
              Proizvodi
              {shouldShowCategories && (
                <div
                  style={{ height: '10000px', backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                  className="w-full absolute top-18 left-0 z-10"
                >
                  <div className="flex gap-20 w-full bg-white pt-8 px-20 h-96">
                    <div className="flex flex-col gap-2">
                      {sortedGroups && sortedGroups.length && sortedGroups.map((group) => {
                        return (
                          <div
                            className="flex gap-3"
                            key={uuid()}
                            onMouseOver={() => setMainGroup(group)}
                            onClick={() => goToGroup(group)}
                          >
                            <div>{group.Name}</div>
                            {firstHoveredGroup && !!firstHoveredGroup.children.length && firstHoveredGroup.Code === group.Code && <ChevronRight />}
                          </div>
                        )
                      })}
                    </div>
                    {firstHoveredGroup && !!firstHoveredGroup.children.length && (
                      <div className="flex flex-col gap-2">
                        {firstHoveredGroup.children.map((group) => {
                          return (
                            <div
                              className="flex gap-3"
                              key={uuid()}
                              onMouseOver={() => setSecondHoveredGroup(group)}
                              onClick={() => goToGroup(group)}
                            >
                              <div>{group.Name}</div>
                              {secondHoveredGroup && !!secondHoveredGroup.children.length && secondHoveredGroup.Code === group.Code && <ChevronRight />}
                            </div>
                          )
                        })}
                      </div>
                    )}
                    {secondHoveredGroup && !!secondHoveredGroup.children.length && (
                      <div className="flex flex-col gap-2">
                        {secondHoveredGroup.children.map((group) => {
                          return <div key={uuid()} onClick={() => goToGroup(group)}>{group.Name}</div>
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className="cursor-pointer hover:text-orange-400 font-bold"
              onClick={() => navigate('/kontakt')}
            >
              Kontakt
            </div>
          </div>
          <div className="flex xl:hidden items-center gap-4">
            <MenuIcon onClick={() => setShouldShowPhoneMenu(true)} />
            <MobileSearchIcon onClick={() => setShouldShowPhoneSearch(true)} />
          </div>
        </div>
        <div className="hidden xl:block relative">
          <input
            type="search"
            className="py-2 px-4 bg-zinc-100 outline-none text-sm text-gray-700 w-160 rounded focus:placeholder-none bg-zinc-50"
            placeholder="Pretraga proizvoda"
            onChange={(e) => searchForModels(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <SearchIcon
            className="absolute top-1.5 right-4"
          />

          {searchQuery && (
            <div
              className="absolute bg-white rounded w-full border border-solid
                border-gray-200 text-gray-700 top-10"
              ref={ref}
            >
              {searchResults.length ? (
                searchResults.slice(0, 5).map((result) => {
                  return (
                    <div
                      key={uuid()}
                      className="flex items-center gap-3 border-b border-solid border-gray-200
                        p-2 cursor-pointer hover:bg-zinc-100"
                      onClick={(e) => navigateToModel(e, result)}
                    >
                      <div className="h-8 w-8">
                        <img src={result.item.ImageWebP} alt="product" />
                      </div>
                      <div>
                        <div className="text-sm">{result.item.Name}</div>
                        <div className="font-normal text-sm">{result.item.Description}</div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="p-2">Nema rezultata</div>
              )}
            </div>
          )}
        </div>
      </div>
      {shouldShowPhoneMenu && (
        <div className="flex flex-col w-full h-full fixed top-0 left-0 bg-white p-10">
          <div className="flex items-center justify-between">
            <div className="text-2xl font-medium text-gray-700">Meni</div>
            <CloseIcon onClick={handleClosePhoneMenu} />
          </div>
          <div
            className="h-full flex flex-col justify-center items-center
              gap-4 text-xl font-medium text-gray-700"
          >
            {shouldShowPhoneCategories ? (
              <div className="flex flex-col gap-1.5">
                {isSuccess && categories.length && categories.map((category) => {
                  return isMainCategory(category)
                })}
              </div>
            ) : (
              <>
                <div onClick={() => navigateToPage('/modeli')}>
                  Svi modeli
                </div>
                <div onClick={() => setShouldShowPhoneCategories(true)}>
                  Kategorije
                </div>
                <div onClick={() => navigateToPage('/kontakt')}>
                  Kontakt
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {shouldShowPhoneSearch && (
        <div className="flex flex-col w-full h-full fixed top-0 left-0 bg-white p-10">
          <div className="flex items-center justify-between">
            <div className="text-2xl font-medium text-gray-700">Pretraga proizvoda</div>
            <CloseIcon onClick={() => setShouldShowPhoneSearch(false)} />
          </div>
          <div className="relative mt-10">
            <input
              type="search" 
              className="w-full border-b border-solid border-zinc-100 p-2 rounded-md outline-none"
              onChange={(e) => searchForModels(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <SearchIcon
              className="absolute top-2 right-4"
            />
          </div>
          {mobileSearchQuery && (
            <div className="w-full text-gray-700 mt-10">
              {searchResults.length ? (
                searchResults.slice(0, 5).map((result) => {
                  return (
                    <div
                      key={uuid()}
                      className="flex items-center gap-3 border-b border-solid border-zinc-100
                        p-2 cursor-pointer hover:bg-zinc-100"
                      onClick={(e) => navigateToModel(e, result)}
                    >
                      <div className="h-8 w-8">
                        <img src={result.item.ImageWebP} alt="product" />
                      </div>
                      <div>
                        <div className="text-sm">{result.item.Name}</div>
                        <div className="font-normal text-sm">{result.item.Description}</div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="p-2">Nema rezultata</div>
              )}
            </div>
          )}
      </div>
      )}
    </div>
  );
}

export default TopBar;