import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import ReactPaginate from 'react-paginate';
import ModelCard from '../components/products/ModelCard';

const modelsPerPage = 16;

function SearchPage() {
  const { state } = useLocation();

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [modelsToShow, setModelsToShow] = useState([]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * modelsPerPage) % state.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  const isLastPage = () => {
    return currentPage + 1 === numberOfPages;
  };

  useEffect(() => {
    if (state && state.length) {
      const pageCount = Math.ceil(state.length / modelsPerPage);
      setNumberOfPages(pageCount);
    }
  }, [state]);

  useEffect(() => {
    if (state && state.length) {
      const endOffset = itemOffset + modelsPerPage;
      const currentItems = state.slice(itemOffset, endOffset);
      setModelsToShow([...currentItems]);
    }
  }, [itemOffset, state]);

  return (
    <>
      {state && state.length ? (
        <div className="flex flex-col">
          <h1 className="font-bold text-gray-700 text-3xl">
            Rezultati pretrage
          </h1>
          <div className="mt-10 grid-container">
            {modelsToShow.map((result) => {
              return <ModelCard key={uuid()} model={result.item} />
            })}
          </div>
          <ReactPaginate
            containerClassName="flex flex-wrap self-center xl:self-end mt-10 text-sm text-gray-700 select-none"
            pageClassName="mx-2"
            activeClassName="text-orange-600 font-medium"
            breakLabel="..."
            nextLabel={isLastPage() ? '' : '»'}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={numberOfPages}
            previousLabel={itemOffset ? '«' : ''}
            renderOnZeroPageCount={null}
          />
        </div>
      ) : (
        <div>Nema rezultata</div>
      )}
    </>
  );
}
  
export default SearchPage;