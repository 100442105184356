// eslint-disable-next-line
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import randomModelsReducer from '../components/products/randomModelsSlice';
import { apiSlice } from '../api/apiSlice';

export const store = configureStore({
  reducer: {
    randomModels: randomModelsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([apiSlice.middleware])
});