function OutletWrapper({ children }) {
  return (
    <>
      <div className="px-4 md:px-20 py-10 overflow-y-scroll main-container">
        {children}
      </div>
    </>
  );
}
  
export default OutletWrapper;