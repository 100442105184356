import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//  http://localhost:4000/

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://biancodesign.rs/' }),
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => '/getCategories'
    }),
    getGroups: builder.query({
      query: () => '/getGroups'
    }),
    getColors: builder.query({
      query: () => '/getColors'
    }),
    getModels: builder.query({
      query: () => '/getModels'
    }),
    getModel: builder.query({
      query: (id) => `/getModel/?id=${id}`
    }),
    getProducts: builder.query({
      query: () => '/getProducts'
    }),
    getProduct: builder.query({
      query: (id) => `/getProduct/?id=${id}`
    }),
    getProductArrivals: builder.query({
      query: () => '/getProductArrivals'
    })
  })
})

export const {
  useGetCategoriesQuery,
  useGetGroupsQuery,
  useGetColorsQuery,
  useGetModelsQuery,
  useGetModelQuery,
  useGetProductsQuery,
  useGetProductQuery,
  useGetProductArrivalsQuery
} = apiSlice;