import { useEffect } from 'react';
import { useGetProductQuery } from '../../api/apiSlice';

function GetProductInfo({
  productId, 
  returnImage,
  returnColor,
  setProductImage,
  setSelectedProductInfo,
  isSelected
}) {  
  const {
    data: product,
    isLoading,
    isError
  } = useGetProductQuery(productId);

  const handleProductSelection = () => {
    setProductImage(product.Images[0].ImageWebP);
    setSelectedProductInfo(product);
  };

  useEffect(() => {
    if (product && isSelected) {
      handleProductSelection();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, isSelected]);

  if (isLoading) return <div className="flex w-full h-full justify-center items-center text-xs" />
  if (isError) return <div>error</div>
  if (returnImage) return (
    <div
      className="flex-0 h-24 w-24 shadow-md rounded-md cursor-pointer overflow-hidden"
      onClick={handleProductSelection}
    >
      <img src={product.Images[0].ImageWebP} alt="product" />
    </div>
  )
  if (returnColor && product.Color) {
    if (product.Color.HtmlColor) {
      return (
        <div
          className={`h-8 w-8 rounded-full cursor-pointer
            ${isSelected && 'border-2 border-solid border-orange-600'}`}
          style={{ backgroundColor: product.Color.HtmlColor }} 
          onClick={handleProductSelection}
        />
      )
    }
    return (
      <div
        className={`h-8 w-8 rounded-full cursor-pointer overflow-hidden
          ${isSelected ? 'border-2 border-solid border-orange-600' : 'border-2 border-solid border-gray-200'}`}
        onClick={handleProductSelection}
      >
        <img src={product.Color.Image} alt="color" />
      </div>
    )
  }
  if (returnColor && product.Shade) {
    if (product.Shade.HtmlColor) {
      return (
        <div
          className={`h-8 w-8 rounded-full cursor-pointer
            ${isSelected && 'border-2 border-solid border-orange-600'}`}
          style={{ backgroundColor: product.Shade.HtmlColor }} 
          onClick={handleProductSelection}
        />
      )
    }
    return (
      <div
        className={`h-8 w-8 rounded-full cursor-pointer overflow-hidden
          ${isSelected ? 'border-2 border-solid border-orange-600' : 'border-2 border-solid border-gray-200'}`}
        onClick={handleProductSelection}
      >
        <img src={product.Shade.Image} alt="color" />
      </div>
    )
  }
}
    
export default GetProductInfo;