import { useState } from "react";
import emailjs from "@emailjs/browser";

const template_id = 'template_k9pe179';
const service_id = 'service_0a0wmfl';
const key = 'dFP7yRlHFfCPtltFI';
const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const defaultErrors = {
  name: false,
  email: false,
  message: false
}

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState(defaultErrors);
  const [hasValidEmail, setHasValidEmail] = useState(true);

  const isEmailValid = () => {
    if (email.match(emailFormat)) {
      setHasValidEmail(true);
      return true;
    }
    setHasValidEmail(false);
    return false;
  };

  const hasEmptyFields = () => {
    const newErrors = {
      name: false,
      email: false,
      message: false
    }
    if (!name) newErrors.name = true;
    if (!email) newErrors.email = true;
    if (!message) newErrors.message = true;

    setErrors(newErrors);

    return Object.values(newErrors).find((error) => {
      return error === true;
    });
  };

  const sendEmail = () => {
    setErrors(defaultErrors);
    if (hasEmptyFields()) return;
    if (!isEmailValid()) return;
    const templateParams = {
      from_name: name,
      user_email: email,
      message: message
    }
    emailjs.send(service_id, template_id, templateParams, key);
  }

  return (
    <div className="text-gray-700 flex flex-col justify-center items-center gap-16">
      <h1 className="font-medium text-3xl xl:text-5xl">Kontaktirajte nas 👋</h1>
      <div className="w-full md:w-auto flex flex-col xl:flex-row justify-center gap-10 xl:gap-40">
        <div className="flex flex-col gap-2">
          <div className="text-2xl font-medium">Informacije</div>
          <div>Tomazeova 23, Beograd, Srbija</div>
          <div>+381 11 244 0388</div>
          <div>+381 63 351 445</div>
          <div>+381 63 101 3808</div>
          <div>biancodesign@mts.rs</div>
          <div>radmilabicanski@gmail.com</div>
        </div>
        <div className="flex flex-col gap-6 w-full md:w-160 rounded">
          <label className="flex flex-col w-full gap-2">
            Vaše ime
            {errors.name && <div className="text-red-600 text-sm font-bold">Ime je obavezno polje</div>}
            <input
              className="w-full px-3 py-2 bg-zinc-50 rounded
                border border-solid border-transparent outline-none focus:border-gray-200"
              placeholder="Unesite vaše ime"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <label className="flex flex-col w-full gap-2">
            Email
            {errors.email && <div className="text-red-600 text-sm font-bold">E-mail je obavezno polje</div>}
            {!hasValidEmail && <div className="text-red-600 text-sm font-bold">E-mail je pogrešnog formata</div>}
            <input
              className="w-full px-3 py-2 bg-zinc-50 rounded
                border border-solid border-transparent outline-none focus:border-gray-200"
              placeholder="Unesite vašu email adresu"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <div className="w-full">
            <p className="mb-2">Poruka</p>
            {errors.message && <div className="text-red-600 text-sm font-bold">Poruka je obavezno polje</div>}
            <textarea
              className="w-full px-3 py-2 h-32 bg-zinc-50 rounded
                border border-solid border-transparent outline-none focus:border-gray-200"
              placeholder="Vaša poruka"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div
            className="w-40 xl:w-64 py-3 bg-orange-600 text-white text-center font-medium rounded cursor-pointer"
            onClick={sendEmail}
          >
            Pošalji ↗
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default Contact;